const routes = {
    /* Guest */
    WELCOME: '/',
    LOGIN: '/login',
    LANDING: '/',

    /* Admin */
    DASHBOARD: '/dashboard',
    ADMIN_LIST: '/admin-list',
    HOME_SLIDERS: '/home-sliders',
    COLLEGE: '/college',
    NOTICE: '/notice',
    COMMITTEE: '/committee',
    LIBRARY: '/library',
    TEACHERS_LIST: '/teachers-list',
    STUDENT_LIST: '/student-list',
    SKILL_DEVELOPMENT: '/skill-development',
    STUDENT_DATABASE: '/student-database',
    STUDENT_BANKING: '/student-banking',
    DEPARTMENT_INFO: '/department-info',
    SCIENCE_DIVISION: '/science',
    BUS_CAR: '/bus-car',
    PRL_LIST: '/prl-list',
    PHOTO_GALLERY: '/publications',
    CONTACTS: '/contacts',
    SPORT_CORNER: '/sports',
    OTHERS: '/others',
    TELEPHONE: '/telephone',
    DEPARTMENT_DETAILS: '/department-details',
    HONS_DATA: '/hons-data',
    CLUB_ADD: '/club-add',
    CLUB_EDIT: '/club-edit',

    DEPT_ADMIN: '/department-admin',
    DEPT_ADMIN_HOME: '/department-admin/home',
    DEPT_ADMIN_HSC_DATA: '/department-admin/hsc',
    DEPT_ADMIN_HONS_DATA: '/department-admin/hons',
    DEPT_ADMIN_ADMINIS: '/department-admin/administrative',
    DEPT_ADMIN_SPORTS: '/department-admin/sports',
};

export default routes;
