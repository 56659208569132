import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useCommon } from 'hooks/useCommon';
import TabPanel from 'components/common/TabPanelCommon';
import PreviewCommon from 'components/common/PreviewCommon';
import { Alert, Typography } from '@mui/material';

const ContactPage = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (_, v) => setValue(v);

    const { items } = useCommon();
    console.log({ items });

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="যোগাযোগ" />
                        <Tab label="গুরুত্বপূর্ন লিংক" />
                    </Tabs>
                </Box>
                <Alert severity="error" sx={{ m: 5 }}>
                    Format: Title, Link
                </Alert>
                <TabPanel value={value} index={0}>
                    <PreviewCommon itemKey={'contacts'} showTextArea />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PreviewCommon itemKey={'importantLinks'} showTextArea />
                </TabPanel>
            </Box>
        </div>
    );
};

export default ContactPage;
