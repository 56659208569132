import React from 'react';
import PageTitle from 'components/header/PageTitle';
import { Stack, Typography } from '@mui/material';
import DepartmentList from './department-info/DepartmentList';
import routes from '../../data/routes';
import { useAuth } from 'hooks/useAuth';
import PreviewCommon from 'components/common/PreviewCommon';

const Telephone = () => {
    const { profile } = useAuth();

    console.log(profile);

    return (
        <div>
            <PageTitle color="primary.main" title="Telephone" />
            <Stack direction="column" sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}>
                <Typography variant="h6" color="primary.main" fontWeight={600}>
                    Telephone numbers
                </Typography>
                <PreviewCommon itemKey="telephones" showImages />
            </Stack>
        </div>
    );
};

export default Telephone;

export const list = [
    { id: 1, title: 'অধ্যক্ষ', objectKey: 'principle', onlyList: true },
    { id: 2, title: 'উপাধ্যক্ষ', objectKey: 'vicePrinciple', onlyList: true },
    { id: 3, title: 'লাইব্রেরি বিভাগ', objectKey: 'library', onlyList: true },
    {
        id: 4,
        title: 'শারীরিক বিভাগ',
        objectKey: 'physicalEducation',
        onlyList: true,
    },
    { id: 5, title: 'অফিস স্টাফ', objectKey: 'officeStuff', onlyList: true },
    { id: 6, title: 'আইটি বিভাগ', objectKey: 'ITStuff', onlyList: true },
];
