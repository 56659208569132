import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreviewCommon from 'components/common/PreviewCommon';
import TabPanel from 'components/common/TabPanelCommon';
import FacultyList from '../department-info/FacultyList';
import { useDepartment } from 'hooks/useDepartment';

const CollegeHome = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (_, v) => setValue(v);

    const { getAllDepartments, data } = useDepartment();

    useEffect(() => {
        getAllDepartments();
    }, []);

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="কলেজ সংক্রান্ত তথ্য" />
                        <Tab label="প্রাক্তন অধ্যক্ষগন" />
                        <Tab label="অধ্যক্ষ" />
                        <Tab label="উপাধ্যক্ষ" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <PreviewCommon
                        itemKey={'collegeInfo'}
                        warning="Upload single PDF Here"
                        showImages
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PreviewCommon itemKey={'exPrinciples'} showTextArea />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FacultyList objectKey={'principle'} list={data['principle']?.faculties} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <FacultyList
                        objectKey={'vicePrinciple'}
                        list={data['vicePrinciple']?.faculties}
                    />
                </TabPanel>
            </Box>
        </div>
    );
};

export default CollegeHome;
