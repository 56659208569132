import { Home } from '@mui/icons-material';
import routes from './routes';

const menuItems = [
    {
        id: '1',
        title: 'Dashboard',
        route: routes.DASHBOARD,
        icon: <Home />,
    },
];

export default menuItems;
