import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useDepartment = create((set, get) => ({
    isLoading: false,
    isAdding: false,
    data: [],

    addFaculties: async (formData, onDone) => {
        set(() => ({ isAdding: true }));
        try {
            await apiClient.post('/admin/department-faculties', formData);
            toast.success('Added');
            set(() => ({ isAdding: false }));
            get().getAllDepartments();
            onDone && onDone();
        } catch (error) {
            set(() => ({ isAdding: false }));
        }
    },

    addFacultiesOnly: async (formData, onDone) => {
        set(() => ({ isAdding: true }));
        try {
            await apiClient.post('/admin/department-faculties', formData);
            toast.success('Added');
            set(() => ({ isAdding: false }));
            onDone();
        } catch (error) {
            set(() => ({ isAdding: false }));
        }
    },

    addDeptOthers: async (formData, onDone) => {
        set(() => ({ isAdding: true }));
        try {
            await apiClient.post('/admin/department-others', formData);
            toast.success('Added');
            set(() => ({ isAdding: false }));
            get().getAllDepartments();
            onDone();
        } catch (error) {
            set(() => ({ isAdding: false }));
        }
    },

    getAllDepartments: async () => {
        set(() => ({ isLoading: true }));
        try {
            const response = await apiClient.get('/admin/department');
            set(() => ({ isLoading: false, data: response.data.data }));
        } catch (error) {
        } finally {
            set(() => ({ isLoading: false }));
        }
    },
}));
