import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { useDepartment } from 'hooks/useDepartment';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import routes from 'data/routes';

export default function DepartmentList({ list }) {
    const { getAllDepartments, isLoading, data } = useDepartment();

    const navigate = useNavigate();

    const toDetailsPage = (item) => {
        navigate(routes.DEPARTMENT_DETAILS, {
            state: { ...item, ...data[item.objectKey] },
        });
    };

    React.useEffect(() => {
        if (!data) getAllDepartments();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Grid container spacing={2} my={1}>
            {list.map((item, index) => (
                <Grid key={index} container item xs={6} md={4} direction="column">
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => toDetailsPage(item)}
                        type="button"
                    >
                        <ListItemText sx={{ textAlign: 'center' }} primary={item.title} />
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}
