import { Box, Button, Container, Stack } from '@mui/material';
import NoticeCard from 'components/cards/NoticeCard';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import PageTitle from 'components/header/PageTitle';
import AddNoticeModal from 'components/modals/AddNoticeModal';
import EditNoticeModal from 'components/modals/EditNoticeModal';
import { useNotice } from 'hooks/useNotice';
import React from 'react';

const Notice = () => {
    const { isLoading, getAllNotices, notices, removeNotice } = useNotice();

    const [open, setOpen] = React.useState(false);

    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [editData, setEditData] = React.useState();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        getAllNotices();
    }, []);

    function onEditPress(data) {
        console.log(data);
        setEditData(data);
        setIsEditOpen(true);
    }

    function onDeletePress(id) {
        if (window.confirm('Are you sure?')) {
            removeNotice(id);
        }
    }

    return (
        <div>
            <PageTitle color="black" title="নোটিশ" />
            {isLoading && <LoadingSpinner />}
            <Container>
                <Stack direction="row" sx={{ padding: '1rem 0' }}>
                    <Button onClick={handleOpen} variant="contained" color="primary">
                        নতুন নোটিশ
                    </Button>
                    <AddNoticeModal open={open} handleClose={handleClose} />
                    <EditNoticeModal
                        notice={editData}
                        open={isEditOpen}
                        handleClose={() => setIsEditOpen(false)}
                    />
                </Stack>
                <Box sx={{ width: '100%' }}>
                    {notices?.map((notice) => {
                        return (
                            <NoticeCard
                                onDeletePress={onDeletePress}
                                onEditPress={onEditPress}
                                key={notice._id}
                                notice={notice}
                            />
                        );
                    })}
                </Box>
            </Container>
        </div>
    );
};

export default Notice;
