import React from 'react';
import styled from '@emotion/styled';
import { StyledLink } from 'styles/styled-component/Links';
import { Grid, Box } from '@mui/material';
import { Campaign } from '@mui/icons-material';

const MenuItemCard = ({ color, title, icon, Imagesrc, linkHref }) => {
  return (
    <Grid item xs={6} sm={3}>
      <StyledLink to={linkHref || '/404'}>
        <CustomCategoryBox
          sx={{ bgcolor: 'primary.main', borderRadius: '5px' }}
        >
          <CustomCategoryBoxContent>
            <CustomCategoryBoxTitle>
              <span>{title}</span>
            </CustomCategoryBoxTitle>
            <CustomCategoryIcon color="#fff">
              {icon || <Campaign />}
            </CustomCategoryIcon>
          </CustomCategoryBoxContent>
        </CustomCategoryBox>
      </StyledLink>
    </Grid>
  );
};

export default MenuItemCard;

const CustomCategoryBox = styled(Box)`
  width: 100%;
  height: 100%;
`;

const CustomCategoryBoxContent = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding: 10px;
  font-size: clamp(1rem, 1.7vw, 2rem);
`;

const CustomCategoryBoxTitle = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
  color: white;
  font-weight: 500;
`;
const CustomCategoryIcon = styled.span`
  border-radius: 50%;
  background-color: ${(props) => props.color};
  padding: 10px;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 20px;
  font-size: clamp(1.5rem, 2vw, 3.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
`;
