import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreviewCommon from 'components/common/PreviewCommon';
import TabPanel from 'components/common/TabPanelCommon';
import { useAuth } from 'hooks/useAuth';

const SportsCornerPage = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_, v) => setValue(v);

  const { profile, role } = useAuth();

  if (role === 'deptAdmin' && profile?.assignedTo !== 'sportsAdmin') {
    return <p>Invalid access</p>;
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="ইভেন্ট" />
            <Tab label="অর্জন" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PreviewCommon itemKey={'sportEvents'} showImages />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PreviewCommon itemKey={'sportsAchievement'} showImages />
        </TabPanel>
      </Box>
    </div>
  );
};

export default SportsCornerPage;
