import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreviewCommon from 'components/common/PreviewCommon';
import { useCommittee } from 'hooks/useCommittee';
import { CommitteeList } from './CommitteeList';

const Committee = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (_, v) => setValue(v);
    const { getAllCommittee, data } = useCommittee();

    useEffect(() => {
        getAllCommittee();
    }, []);

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="সম্পাদনা পরিষদ" />
                        <Tab label="শিক্ষক পরিষদের পর্যায়ক্রমিক তালিকা" />
                        <Tab label="শিক্ষক পরিষদ ২০XX" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <CommitteeList objectKey={'editors'} list={data['editors']?.members || []} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PreviewCommon itemKey={'committeeByYear'} showTextArea />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CommitteeList objectKey={'teachers'} list={data['teachers']?.members || []} />
                </TabPanel>
            </Box>
        </div>
    );
};

export default Committee;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
