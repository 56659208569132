import { LoadingButton } from '@mui/lab';
import apiClient from 'api/apiClient';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Avatar, Stack } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { config } from 'utils/config';
import { useCommon } from 'hooks/useCommon';

const ImageUploader = ({ imageUrl, onUploadImage, setImageUrl }) => {
    const [files, setFiles] = useState('');
    const [caption, setCaption] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const { removeFileByUrl } = useCommon();

    async function onFileSelect(e) {
        const file = e.target.files[0];
        if (file.size > 1024 * 1024 * 20)
            return toast.error('File size cannot exceed more than 20MB');

        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', 'Dogesh');
        try {
            setIsUploading(true);
            const response = await apiClient.post('/admin/uploadSingle', formData);
            // console.log({ response });
            if (response.data?.path) {
                setFiles({ url: response.data.path, caption });
            }
            setCaption('');
            onUploadImage({ url: response.data.path, caption });
        } catch (error) {
            console.log('err', error);
        } finally {
            setIsUploading(false);
            e.target.value = null;
        }
    }

    const onRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (files.url) {
            removeFileByUrl(files.url);
            setFiles('');
        }
        if (imageUrl) {
            removeFileByUrl(imageUrl);
            setFiles('');
            setImageUrl(null);
        }
    };

    // console.log({ files, imageUrl });

    let imgUrl = '';

    if (files?.url) imgUrl = config.fileUrl + files?.url;
    if (imageUrl) imgUrl = config.fileUrl + imageUrl;

    return (
        <Stack direction={'row'} alignItems={'center'} my={2}>
            {files?.url || imageUrl ? (
                <>
                    <Avatar sx={{ height: 50, width: 50 }} src={imgUrl} />
                    <LoadingButton
                        color="error"
                        sx={{ ml: 2 }}
                        component="label"
                        loading={isUploading}
                        onClick={(e) => onRemove(e)}
                    >
                        Remove Pic
                    </LoadingButton>
                </>
            ) : (
                <>
                    <Avatar sx={{ bgcolor: deepOrange[500], height: 50, width: 50 }}>N/A</Avatar>
                    <LoadingButton sx={{ ml: 2 }} component="label" loading={isUploading}>
                        Upload
                        <input hidden accept="image/*" type="file" onChange={onFileSelect} />
                    </LoadingButton>
                </>
            )}
        </Stack>
    );
};

export default ImageUploader;
