import PreviewCommon from 'components/common/PreviewCommon';
import React from 'react';

const PhotoGallery = () => {
    return (
        <div>
            <h2 style={{ textAlign: 'center' }}>Photo Gallery</h2>
            <PreviewCommon itemKey={'photoGallery'} showImages />
        </div>
    );
};

export default PhotoGallery;
