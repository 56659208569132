import React from 'react';
import {
    Avatar,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { colors } from '@mui/material';

import icon from './logo.png';

const DrawerMenu = ({ menuItems, isOpen, onClose }) => {
    const navigate = useNavigate();

    const { logoutUser } = useAuth();

    function handleMenuItemClick(route) {
        navigate(route);
        onClose();
    }

    function handleLogout() {
        logoutUser(navigate);
    }

    return (
        <Drawer anchor={'left'} open={isOpen}>
            <List
                sx={{
                    padding: 2,
                    position: 'relative',
                    height: '100%',
                }}
            >
                <Stack>
                    <IconButton sx={{ marginLeft: 'auto' }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Avatar
                    src={icon}
                    sx={{ height: 100, width: 100, margin: '0 auto', marginBottom: 2 }}
                />
                {menuItems.map(({ id, title, route, icon }) => (
                    <ListItem key={id} disablePadding onClick={() => handleMenuItemClick(route)}>
                        <ListItemButton>
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText color="gray" primary={title} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Stack
                    sx={{
                        color: 'red',
                        width: '100%',
                    }}
                >
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <ExitToAppIcon sx={{ color: colors.pink[500] }} />
                        </ListItemIcon>
                        <ListItemText color="gray" primary="Logout" />
                    </ListItemButton>
                </Stack>
            </List>
        </Drawer>
    );
};

export default DrawerMenu;
