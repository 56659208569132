import { Stack, Typography } from '@mui/material';
import PageTitle from 'components/header/PageTitle';
import DepartmentList from 'pages/admin-pages/department-info/DepartmentList';
import { artsList, businessList, honsData, scienceList, socialScienceList } from './constants';

const DepartmentInfo = () => {
    return (
        <>
            <PageTitle color="primary.main" title=" Department Information" />
            <Stack direction="column" sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}>
                <Typography variant="h6" color="primary.main" fontWeight={600}>
                    কলা অনুষদ
                </Typography>
                <DepartmentList list={artsList} />
            </Stack>
            <Stack direction="column" sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}>
                <Typography variant="h6" color="primary.main" fontWeight={600}>
                    সামাজিক বিজ্ঞান অনুষদ
                </Typography>
                <DepartmentList list={honsData} />
            </Stack>
            <Stack direction="column" sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}>
                <DepartmentList list={socialScienceList} />
            </Stack>
            <Stack direction="column" sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}>
                <Typography variant="h6" color="primary.main" fontWeight={600}>
                    বিজ্ঞান অনুষদ
                </Typography>
                <DepartmentList list={honsData} />
            </Stack>
            <Stack direction="column" sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}>
                <DepartmentList list={scienceList} />
            </Stack>
            <Stack direction="column" sx={{ marginTop: '2rem', pl: { xs: 1, md: 2 } }}>
                <Typography variant="h6" color="primary.main" fontWeight={600}>
                    ব্যবসায় শিক্ষা অনুষদ
                </Typography>
                <DepartmentList list={businessList} />
            </Stack>
        </>
    );
};

export default DepartmentInfo;
