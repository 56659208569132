import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useCommittee = create((set, get) => ({
    isLoading: false,
    isAdding: false,
    data: [],

    updateCommittee: async (formData, onDone) => {
        set(() => ({ isAdding: true }));
        try {
            await apiClient.post('/admin/committee', formData);
            toast.success('Added');
            set(() => ({ isAdding: false }));
            get().getAllCommittee();
            onDone();
        } catch (error) {
            set(() => ({ isAdding: false }));
        }
    },

    getAllCommittee: async () => {
        set(() => ({ isLoading: true }));
        try {
            const response = await apiClient.get('/admin/committee');
            set(() => ({ isLoading: false, data: response.data.data }));
        } catch (error) {
        } finally {
            set(() => ({ isLoading: false }));
        }
    },
}));
