import { Tab, Tabs, Box, Typography } from '@mui/material';
import TabPanel from 'components/common/TabPanelCommon';
import { useDepartment } from 'hooks/useDepartment';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FacultyList from './FacultyList';
import FacultyOthers from './FacultyOthers';

const DepartmentDetails = () => {
    const { getAllDepartments, data } = useDepartment();

    const [value, setValue] = React.useState(0);
    const handleChange = (_, v) => setValue(v);
    const { state } = useLocation();

    useEffect(() => {
        getAllDepartments();
    }, []);

    return (
        <>
            <Typography variant="h6" textAlign={'center'}>
                {state.title}
            </Typography>
            <Box>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Faculty list" />
                    <Tab label="Other info" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <FacultyList
                    objectKey={state.objectKey}
                    list={data[state.objectKey]?.faculties}
                    allData={data}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FacultyOthers objectKey={state.objectKey} data={data[state.objectKey]} />
            </TabPanel>
        </>
    );
};

export default DepartmentDetails;
