import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import PreviewCommon from 'components/common/PreviewCommon';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/common/TabPanelCommon';
import Box from '@mui/material/Box';
import { useDepartment } from 'hooks/useDepartment';
import FacultyList from './department-info/FacultyList';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

const OthersPage = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (_, v) => setValue(v);

    const { getAllDepartments, data, isLoading } = useDepartment();

    useEffect(() => {
        getAllDepartments();
    }, []);

    return (
        <div>
            {isLoading && <LoadingSpinner />}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="লাইব্রেরি বিভাগ" />
                        <Tab label="শারীরিক শিক্ষা বিভাগ" />
                        <Tab label="অফিস স্টাফ" />
                        <Tab label="আইটি বিভাগ" />
                        <Tab label="ছুটির তালিকা" />
                        <Tab label="বদলি শিক্ষকবৃন্দ" />
                        <Tab label=" বদলি অফিস স্টাফ" />
                        <Tab label="সিটিজেন চার্টার" />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                    <FacultyList objectKey={'library'} list={data['library']?.faculties} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FacultyList objectKey={'physicalEd'} list={data['physicalEd']?.faculties} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FacultyList objectKey={'officeStuff'} list={data['officeStuff']?.faculties} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <FacultyList objectKey={'itStuff'} list={data['itStuff']?.faculties} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Alert severity="error" sx={{ m: 5 }}>
                        Allowed multiple images or single PDF
                    </Alert>
                    <PreviewCommon itemKey={'holidayList'} showImages />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <FacultyList
                        objectKey={'transferredTeachers'}
                        list={data['transferredTeachers']?.faculties}
                    />
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <FacultyList
                        objectKey={'transferredOfficials'}
                        list={data['transferredOfficials']?.faculties}
                    />
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <Alert severity="error" sx={{ m: 5 }}>
                        Allowed PDF Only
                    </Alert>
                    <PreviewCommon itemKey={'citizenCharter'} showImages />
                </TabPanel>
            </Box>
        </div>
    );
};

export default OthersPage;
