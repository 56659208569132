import React, { useEffect } from 'react';
import FacultyList from './department-info/FacultyList';
import { useDepartment } from 'hooks/useDepartment';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

const PrlListPage = () => {
    const { getAllDepartments, data, isLoading } = useDepartment();

    useEffect(() => {
        getAllDepartments();
    }, []);

    return (
        <div>
            {isLoading && <LoadingSpinner />}
            <FacultyList objectKey={'prl'} list={data['prl']?.faculties} />
        </div>
    );
};

export default PrlListPage;
