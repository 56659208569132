import React, { useState } from 'react';
import { Input, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDepartment } from 'hooks/useDepartment';

const FacultyOthers = ({ objectKey, data }) => {
    const { addDeptOthers, isAdding } = useDepartment();

    const [email, setEmail] = useState('');

    const onSubmitData = () => {
        addDeptOthers({ key: objectKey, email });
    };

    React.useEffect(() => {
        if (!data) return;
        if (data.email) setEmail(data.email);
    }, [data]);

    if (!objectKey) return null;

    return (
        <Stack spacing={2}>
            <Stack spacing={2} p={5}>
                <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                />
                <LoadingButton loading={isAdding} onClick={onSubmitData} variant="contained">
                    Update Information
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

export default FacultyOthers;
