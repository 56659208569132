import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import icon from './logo.png';

const LoginPage = () => {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { isLoading, loginUser } = useAuth();

    const handleLogin = (e) => {
        e.preventDefault();
        if (!userId || !password) {
            return toast.error('All fields are required');
        }
        const formValue = { userId, password };
        loginUser(formValue, navigate);
        // loginUser(formValue, navigate);
    };

    return (
        <Stack alignItems={'center'} justifyContent="center" mt={10}>
            {/* <LogoWithName /> */}
            <img src={icon} alt="Logo" height={200} width={200} />
            <form style={{ width: '100%', marginTop: 42 }} onSubmit={handleLogin}>
                <Stack sx={{ width: '100%' }} direction="column" spacing={2} component="from">
                    <TextField
                        id="outlined-required"
                        label="User Id"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <LoadingButton type="submit" loading={isLoading} variant="contained">
                        Submit
                    </LoadingButton>
                </Stack>
            </form>
        </Stack>
    );
};

export default LoginPage;
