// import { LoadingSpinner } from 'components';
import routes from 'data/routes';
import AdminLayout from 'layouts/AdminLayout';
import GuestLayout from 'layouts/GuestLayout';
import { DashboardPage, FourOhFour, LoginPage } from 'pages';
import Notice from 'pages/admin-pages/Notice';
import DepartmentInfo from 'pages/admin-pages/department-info/DepartmentInfo';
import SkillDevelopment from 'pages/admin-pages/SkillDevelopment';
import StudentDatabase from 'pages/admin-pages/student-db';
import StudentBanking from 'pages/admin-pages/StudentBanking';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Divitions from 'pages/admin-pages/department-pages/Divitions';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { getValue } from 'utils/storage';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import LandingPage from 'pages/guest-pages/LandingPage';
import BusCar from 'pages/admin-pages/BusCar';
import PrlList from 'pages/admin-pages/PrlList';
import PhotoGallery from 'pages/admin-pages/PhotoGallery';
import CollegeHome from 'pages/admin-pages/college';
import SportsCornerPage from 'pages/admin-pages/SportsCornerPage';
import ContactPage from '../pages/admin-pages/ContactPage';
import OthersPage from '../pages/admin-pages/OthersPage';
import DepartmentDetails from 'pages/admin-pages/department-info/DepartmentDetails';
import HonsData from 'pages/admin-pages/department-info/DepartmentDetails';
import AdminList from 'pages/admin-pages/admin-list';
import Telephone from 'pages/admin-pages/Telephone';
import Committee from 'pages/admin-pages/Committee';

const AppRouter = () => {
    const { setLoggedIn } = useAuth();

    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();

    const getAuth = async () => {
        const hasToken = getValue('token');
        if (hasToken) {
            setLoggedIn();
            setLoaded(true);
        } else {
            setLoaded(true);
            navigate('/login');
        }
    };

    useEffect(() => {
        getAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!loaded) {
        return <LoadingSpinner />;
    }

    return (
        <Routes>
            <Route path={routes.LANDING} element={<LandingPage />} />

            {/* Guest Routes */}
            <Route element={<GuestLayout />}>
                <Route path={routes.LOGIN} element={<LoginPage />} />
            </Route>

            {/* Admin Routes */}
            <Route element={<AdminLayout />}>
                <Route path={routes.DASHBOARD} element={<DashboardPage />} />
                <Route path={routes.ADMIN_LIST} element={<AdminList />} />
                <Route path={routes.NOTICE} element={<Notice />} />
                <Route path={routes.DEPARTMENT_INFO} element={<DepartmentInfo />} />
                <Route path={routes.SCIENCE_DIVISION} element={<Divitions />} />
                <Route path={routes.COMMITTEE} element={<Committee />} />
                <Route path={routes.BUS_CAR} element={<BusCar />} />
                <Route path={routes.SKILL_DEVELOPMENT} element={<SkillDevelopment />} />
                <Route path={routes.STUDENT_DATABASE} element={<StudentDatabase />} />
                <Route path={routes.STUDENT_BANKING} element={<StudentBanking />} />
                <Route path={routes.PRL_LIST} element={<PrlList />} />
                <Route path={routes.PHOTO_GALLERY} element={<PhotoGallery />} />
                <Route path={routes.COLLEGE} element={<CollegeHome />} />
                <Route path={routes.SPORT_CORNER} element={<SportsCornerPage />} />
                <Route path={routes.CONTACTS} element={<ContactPage />} />
                <Route path={routes.OTHERS} element={<OthersPage />} />
                <Route path={routes.DEPARTMENT_DETAILS} element={<DepartmentDetails />} />
                <Route path={routes.HONS_DATA} element={<HonsData />} />
                <Route path={routes.TELEPHONE} element={<Telephone />} />
            </Route>

            {/* Common Routes */}
            <Route path="*" element={<FourOhFour />} />
        </Routes>
    );
};

export default AppRouter;
