import { Button, Card, IconButton, Stack, TextField } from '@mui/material';
import ImageUploader from 'components/common/ImageUploader';
import React from 'react';
import { Delete } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const FacultyCard = ({
    data,
    handleChange,
    onRemoveImage,
    onRemove,
    onMoveToPRL,
    onMakeTransfer,
}) => {
    const router = useLocation();

    const handlePrlMoving = () => {
        if (window.confirm(`Are you sure move this item to PRL`)) {
            const year = prompt("What's the PRL year?");
            if (year) {
                onMoveToPRL(parseInt(year), data.id, data);
            }
        }
    };

    const handleTransfer = () => {
        const type = prompt(
            'Enter 1 for Transfer to  বদলি হয়ে যাওয়া শিক্ষকবৃন্দ and 2 for  বদলি হয়ে যাওয়া অফিস স্টাফ'
        );
        console.log(type);
        if (parseInt(type) === 1) {
            onMakeTransfer('transferredTeachers', data);
        }
        if (parseInt(type) === 2) {
            onMakeTransfer('transferredOfficials', data);
        }
    };

    return (
        <Card
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                border: '1px dashed gray',
                padding: 16,
                position: 'relative',
            }}
            className="faculty-card"
        >
            <IconButton
                style={{ marginLeft: 'auto', position: 'absolute', right: 4 }}
                aria-label="settings"
                onClick={() => onRemove(data.id)}
            >
                <Delete size="small" sx={{ color: 'red' }} />
            </IconButton>

            <div style={{ width: '100%' }}>
                <ImageUploader
                    onUploadImage={(file) => handleChange(file.url, data.id, 'photo')}
                    imageUrl={data.photo}
                    setImageUrl={() => onRemoveImage(data.id)}
                />
            </div>
            <TextField
                label="serial"
                type="number"
                value={data.serial}
                onChange={(e) => handleChange(e.target.value, data.id, 'serial')}
            />
            <TextField
                label="ID no."
                type="number"
                value={data.id}
                onChange={(e) => handleChange(e.target.value, data.id, 'id')}
            />
            <TextField
                label="Name"
                value={data.name}
                onChange={(e) => handleChange(e.target.value, data.id, 'name')}
            />
            <TextField
                label="Name in En"
                value={data.nameEn}
                onChange={(e) => handleChange(e.target.value, data.id, 'nameEn')}
            />
            <TextField
                label="Designation"
                value={data.designation}
                onChange={(e) => handleChange(e.target.value, data.id, 'designation')}
            />
            <TextField
                label="Govt. Joining"
                value={data.govtJoin}
                onChange={(e) => handleChange(e.target.value, data.id, 'govtJoin')}
            />
            <TextField
                label="PDS ID"
                value={data.govtJoin}
                onChange={(e) => handleChange(e.target.value, data.pds, 'pds')}
            />
            <TextField
                label="This College Joining"
                value={data.collegeJoin}
                onChange={(e) => handleChange(e.target.value, data.id, 'collegeJoin')}
            />
            <TextField
                label="This Designation Joining"
                value={data.currentJoin}
                onChange={(e) => handleChange(e.target.value, data.id, 'currentJoin')}
            />
            <TextField
                label="Birth Date"
                value={data.dob}
                onChange={(e) => handleChange(e.target.value, data.id, 'dob')}
            />
            <TextField
                label="Blood Group"
                value={data.bloodGroup}
                onChange={(e) => handleChange(e.target.value, data.id, 'bloodGroup')}
            />
            <TextField
                label="Address Current"
                value={data.address}
                onChange={(e) => handleChange(e.target.value, data.id, 'address')}
            />
            <TextField
                label="Address Permanent"
                value={data.addressP}
                onChange={(e) => handleChange(e.target.value, data.id, 'addressP')}
            />
            <TextField
                label="Phone no."
                value={data.phone}
                onChange={(e) => handleChange(e.target.value, data.id, 'phone')}
            />
            <TextField
                label="Email Address"
                value={data.email}
                onChange={(e) => handleChange(e.target.value, data.id, 'email')}
            />
            <TextField
                label="Facebook Id Link"
                value={data.facebook}
                onChange={(e) => handleChange(e.target.value, data.id, 'facebook')}
            />
            {router.pathname === '/prl-list' ? (
                <TextField
                    label="PRL Year"
                    type="number"
                    value={data.prlYear}
                    onChange={(e) => handleChange(parseInt(e.target.value), data.id, 'prlYear')}
                />
            ) : null}
            {router.pathname !== '/prl-list' ? (
                <Stack justifyContent="center" direction="row" gap={1} sx={{ width: '100%' }}>
                    <Button
                        size="small"
                        onClick={handlePrlMoving}
                        variant="contained"
                        color="primary"
                    >
                        Move to PRL
                    </Button>
                    <Button size="small" onClick={handleTransfer} variant="contained" color="error">
                        Move to Transferred
                    </Button>
                </Stack>
            ) : null}
        </Card>
    );
};

export default FacultyCard;
