import { Delete } from '@mui/icons-material';
import {
    Button,
    IconButton,
    Input,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { Stack } from '@mui/system';
import ImageUploader from 'components/common/ImageUploader';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useCommittee } from 'hooks/useCommittee';
import React from 'react';

const defaultValues = {
    name: '',
    designation: '',
    image: '',
    phone: '',
};

export const CommitteeList = ({ objectKey, list }) => {
    const { updateCommittee, isLoading } = useCommittee();

    const [data, setData] = React.useState([]);

    const handleChange = (newValue, _id, key) => {
        const newData = data.map((single) => {
            if (single._id === _id) {
                return { ...single, [key]: newValue };
            }
            return single;
        });
        setData(newData);
    };

    const onAddNew = () => {
        setData((prev) => [
            ...prev,
            { ...defaultValues, serial: prev.length + 1, _id: prev.length + 1, type: objectKey },
        ]);
    };

    const onRemoveImage = (_id) => {
        const newData = data.map((item) => {
            if (item._id !== _id) return item;
            return { ...item, image: '' };
        });
        setData(newData);
    };

    console.log(data);

    const onOrder = () => {
        const newData = data.sort((a, b) => a.serial - b.serial);
        setData([...newData]);
    };

    const onRemove = (_id) => {
        const newData = data.filter((item) => item._id !== _id);
        setData([...newData]);
    };

    const onSave = () => {
        const idRemoved = [...data].map(({ _id, ...rest }) => rest);
        updateCommittee({ type: objectKey, members: idRemoved });
    };

    React.useEffect(() => {
        onOrder();
        if (list) setData(list);
    }, [list]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div>
            <Stack py={2} direction="row" spacing={2}>
                <Button variant="contained" onClick={onAddNew}>
                    Add New One
                </Button>
                <Button onClick={onOrder} variant="outlined" color="secondary">
                    Reorder
                </Button>
                <Button onClick={onSave} variant="contained" color="error">
                    Save
                </Button>
            </Stack>

            <Table
                sx={{
                    minWidth: 800,
                    width: { xs: '100%', lg: '90%', xl: '70%' },
                    border: '1px solid #e0e0e0',
                    mr: 2,
                }}
                size="small"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Serial</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Position</TableCell>
                        <TableCell>Designation</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>image</TableCell>
                        <TableCell align="center">Remove</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ overflowY: 'unset' }}>
                    {data?.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                            }}
                        >
                            <TableCell align="left" sx={{ width: 'auto' }}>
                                <Input
                                    type="number"
                                    value={row.serial}
                                    onChange={(e) =>
                                        handleChange(e.target.value, row._id, 'serial')
                                    }
                                />
                            </TableCell>
                            <TableCell align="left" sx={{ width: 'auto' }}>
                                <Input
                                    value={row.name}
                                    onChange={(e) => handleChange(e.target.value, row._id, 'name')}
                                />
                            </TableCell>
                            <TableCell align="left" sx={{ width: 'auto' }}>
                                <Input
                                    value={row.position}
                                    onChange={(e) =>
                                        handleChange(e.target.value, row._id, 'position')
                                    }
                                />
                            </TableCell>
                            <TableCell align="left" sx={{ width: 'auto' }}>
                                <Input
                                    value={row.designation}
                                    onChange={(e) =>
                                        handleChange(e.target.value, row._id, 'designation')
                                    }
                                />
                            </TableCell>
                            <TableCell align="left" sx={{ width: 'auto' }}>
                                <Input
                                    value={row.address}
                                    onChange={(e) =>
                                        handleChange(e.target.value, row._id, 'address')
                                    }
                                />
                            </TableCell>
                            <TableCell align="left" sx={{ width: 'auto' }}>
                                <Input
                                    value={row.phone}
                                    onChange={(e) => handleChange(e.target.value, row._id, 'phone')}
                                />
                            </TableCell>
                            <TableCell align="left">
                                <ImageUploader
                                    onUploadImage={(file) =>
                                        handleChange(file.url, row._id, 'image')
                                    }
                                    imageUrl={row.image}
                                    setImageUrl={() => onRemoveImage(row._id)}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => onRemove(row._id)}>
                                    <Delete size="small" sx={{ color: 'red' }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
