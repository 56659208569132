import { Button, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { Stack } from '@mui/system';
import { useDepartment } from 'hooks/useDepartment';
import * as React from 'react';
import FacultyCard from './FacultyCard';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const defaultValues = {
    name: '',
    designation: '',
    photo: '',
    phone: '000000000',
    email: 'test@test.com',
};

export default function FacultyList({ objectKey, list = [], allData }) {
    const [data, setData] = React.useState(list);
    const navigate = useNavigate();

    const { addFaculties, addFacultiesOnly, isAdding, data: dataOnHook } = useDepartment();

    const handleChange = (newValue, id, key) => {
        const newData = data.map((single) => {
            if (single.id === id) {
                return { ...single, [key]: newValue };
            }
            return single;
        });
        setData(newData);
    };

    const onRemoveImage = (id) => {
        const newData = data.map((item) => {
            if (item.id !== id) return item;
            return { ...item, photo: '' };
        });
        setData(newData);
    };

    const onAddNew = () => {
        setData((prev) => [
            ...prev,
            { ...defaultValues, serial: prev.length + 1, id: prev.length + 1 },
        ]);
    };

    const onOrder = () => {
        const newData = data.sort((a, b) => a.serial - b.serial);
        setData([...newData]);
    };

    const onRemove = (id) => {
        const newData = data.filter((item) => item.id !== id);
        setData([...newData]);
    };

    const onSave = () => {
        addFaculties({ key: objectKey, faculties: data });
    };

    const onMoveToPRL = (year, id, theItem) => {
        // remove from current key
        const newData = data.filter((item) => item.id !== id);
        addFacultiesOnly({ key: objectKey, faculties: newData });

        // add on PRL keys
        const prlData = dataOnHook['prl'] || {};
        const prlFaculties = prlData.faculties || [];
        theItem.prlYear = year;
        theItem.state = 'prl';
        addFaculties({ key: 'prl', faculties: [...prlFaculties, theItem] });

        navigate(-1);
    };

    const onMakeTransfer = (type, theItem) => {
        // remove from current key
        const newData = data.filter((item) => item.id !== theItem.id);
        addFacultiesOnly({ key: objectKey, faculties: newData });

        // add on transferred
        const transferData = dataOnHook[type] || {};
        const transferItems = transferData.faculties || [];
        addFaculties({ key: type, faculties: [...transferItems, theItem] });

        navigate(-1);
    };

    React.useEffect(() => {
        onOrder();
    }, []);

    return (
        <TableContainer component={Paper} sx={{ p: 3 }}>
            <Stack py={2} direction="row" spacing={2}>
                <Button variant="contained" onClick={onAddNew}>
                    Add New One
                </Button>
                <Button onClick={onOrder} variant="outlined" color="secondary">
                    Reorder
                </Button>
                <LoadingButton
                    loading={isAdding}
                    onClick={onSave}
                    variant="contained"
                    color="error"
                >
                    Save
                </LoadingButton>
            </Stack>
            <Grid container spacing={2} mx={2} my={1}>
                {data?.map((item) => (
                    <Grid key={item.id} item xs={12} sm={6} md={4}>
                        <FacultyCard
                            handleChange={handleChange}
                            onRemoveImage={onRemoveImage}
                            key={item.id}
                            data={item}
                            onMoveToPRL={onMoveToPRL}
                            onMakeTransfer={onMakeTransfer}
                            onRemove={onRemove}
                        />
                    </Grid>
                ))}
            </Grid>
        </TableContainer>
    );
}
