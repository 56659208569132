import {
    Campaign,
    Favorite,
    FilterList,
    Groups,
    Link,
    List,
    MenuBook,
    School,
} from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import MenuItemCard from 'components/cards/MenuItemCard';
import VersionUpdater from 'components/cards/VersionUpdater';
import routes from 'data/routes';

const menuItems = [
    {
        id: '3',
        title: `নোটিশ`,
        route: routes.NOTICE,
        icon: <Campaign />,
    },
    {
        id: '33',
        title: `কলেজ`,
        route: routes.COLLEGE,
        icon: <Favorite />,
    },
    {
        id: '5',
        title: 'পরিষদ',
        route: routes.COMMITTEE,
        icon: <MenuBook />,
    },
    {
        id: '4',
        title: 'অনুষদ',
        route: routes.DEPARTMENT_INFO,
        icon: <School />,
    },
    {
        id: '343',
        title: `অন্যান্য`,
        route: routes.OTHERS,
        icon: <Link />,
    },
    {
        id: '353',
        title: `টেলিফোন নাম্বার`,
        route: routes.TELEPHONE,
        icon: <Link />,
    },
    {
        id: '61',
        title: `ফটো গ্যালারি`,
        route: routes.PHOTO_GALLERY,
        icon: <List />,
    },

    {
        id: '7',
        title: 'পি আর এল',
        route: routes.PRL_LIST,
        icon: <Groups />,
    },
    {
        id: '8',
        title: 'যোগাযোগ',
        route: routes.CONTACTS,
        icon: <FilterList />,
    },
];

const DashboardPage = () => {
    return (
        <>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <VersionUpdater />
            </Stack>
            <Grid container spacing={1}>
                {menuItems.map((item, index) => (
                    <MenuItemCard
                        key={index}
                        title={item?.title}
                        linkHref={item?.route}
                        icon={item?.icon}
                    />
                ))}
            </Grid>
        </>
    );
};

export default DashboardPage;
